import { LinearProgress } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { NotFound } from './common/components/404'
import NavBar, { useNavBar } from './common/components/NavBar'
import TopBar from './common/components/TopBar'
import { Home } from './components/Home'
import BillingHistory from './components/billing-projections/BillingHistory'
import Caching from './components/caching/Caching'
import CompanyCreate from './components/company-create/CompanyCreate'
import CompanyDetails from './components/company-details/CompanyDetails'
import ContractDetails from './components/contract-details/ContractDetails'
import { CustomerDashboards } from './components/customer-dashboards/CustomerDashboards'
import { CustomerLocationsMap } from './components/customer-locations-map/CustomerLocationsMap'
import DemoTools from './components/demo/DemoTools'
import FormTemplateCreate from './components/form-template-create/FormTemplateCreate'
import FormTemplateDetails from './components/form-template-details/FormTemplateDetails'
import FormTemplateList from './components/form-template-list/FormTemplateList'
import { FormTemplateMatch } from './components/form-template-match/FormTemplateMatch'
import { FormTemplateTurnaroundDashboard } from './components/form-template-turnaround/FormTemplateTurnaroundDashboard'
import FormTemplateVariableLookup from './components/form-template-variables/FormTemplateVariableLookup'
import FormTemplateVariables from './components/form-template-variables/FormTemplateVariables'
import FormTemplateVersionCreate from './components/form-template-versions/form-template-version-create/FormTemplateVersionCreate'
import {
  FormTemplateEditor,
  TemplateVersionRedirect,
} from './components/form-template-versions/form-template-version-editor/FormTemplateEditor'
import { GcDedupe } from './components/gc-dedupe/GcDedupe'
import JobsPage from './components/jobs/JobsPage'
import LegalDocuments from './components/legal-requirement/LegalDocuments'
import PayAppDetails from './components/pay-app-details/PayAppDetails'
import { ProcessingContractList } from './components/processing-contracts/ProcessingContractList'
import ProjectDetails from './components/project-details/ProjectDetails'
import Reporting from './components/reporting/Reporting'
import CreateOrEditUserCampaign from './components/user-campaigns/CreateOrEditUserCampaign'
import UserCampaigns from './components/user-campaigns/UserCampaigns'
import UserDetails from './components/user-details/UserDetails'
import WriteSyncOperationList from './components/write-sync-operation-list/WriteSyncOperationList'

const useStyles = makeStylesFast(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
}))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export function App() {
  const classes = useStyles()
  const { isOpen, setIsOpen } = useNavBar()

  return (
    <>
      <TopBar onOpenMenu={!isOpen ? () => setIsOpen(true) : undefined} />
      <NavBar onClose={isOpen ? () => setIsOpen(false) : undefined} />
      <div className={classes.container}>
        <div className={classes.content} style={{ paddingLeft: isOpen ? 256 : 16 }}>
          <Suspense fallback={<LinearProgress />}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SentryRoutes>
                <Route path="/" element={<Home />} />
                <Route path="/projects/:projectId" element={<ProjectDetails />} />
                <Route path="/payApps/:payAppId" element={<PayAppDetails />} />
                <Route path="/users/:userId" element={<UserDetails />} />
                <Route path="/companies/create" element={<CompanyCreate />} />
                <Route path="/companies/:companyId" element={<CompanyDetails />} />
                <Route path="/legalDocuments/:requirementId" element={<LegalDocuments />} />
                <Route path="/contracts/:contractId" element={<ContractDetails />} />
                <Route path="/templates" element={<FormTemplateList />} />
                <Route path="/templates/create" element={<FormTemplateCreate />} />
                <Route path="/templates/match" element={<FormTemplateMatch />} />
                <Route path="/templates/:templateId" element={<FormTemplateDetails />} />
                <Route
                  path="/templates/:templateId/versions/create"
                  element={<FormTemplateVersionCreate />}
                />

                {/* Deprecated */}
                <Route
                  path="/templates/:templateId/versions/:versionId"
                  element={<TemplateVersionRedirect />}
                />
                <Route
                  path="/templates/:templateId/versions/:versionId/edit"
                  element={<TemplateVersionRedirect />}
                />

                <Route path="/templates/:templateId/edit" element={<FormTemplateEditor />} />
                <Route path="/templateVariables" element={<FormTemplateVariables />} />
                <Route path="/templateVariables/lookup" element={<FormTemplateVariableLookup />} />
                <Route path="/writeSyncOperations" element={<WriteSyncOperationList />} />
                <Route path="/jobs" element={<JobsPage />} />
                <Route path="/caching" element={<Caching />} />
                <Route path="/reporting" element={<Reporting />} />
                <Route path="/campaigns/create" element={<CreateOrEditUserCampaign />} />
                <Route path="/campaigns/:campaignId" element={<CreateOrEditUserCampaign />} />
                <Route path="/campaigns" element={<UserCampaigns />} />
                <Route path="/customerLocationsMap" element={<CustomerLocationsMap />} />
                <Route path="/gcDedupe" element={<GcDedupe />} />
                <Route path="/formTurnaround" element={<FormTemplateTurnaroundDashboard />} />
                <Route path="/customerDashboards" element={<CustomerDashboards />} />
                <Route path="/billingHistory" element={<BillingHistory />} />
                <Route path="/demoTools" element={<DemoTools />} />
                <Route path="/processingContracts" element={<ProcessingContractList />} />
                <Route path="*" element={<NotFound />} />
              </SentryRoutes>
            </LocalizationProvider>
          </Suspense>
        </div>
      </div>
    </>
  )
}
